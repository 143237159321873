import { defaultLeadsApiCall } from '@itau-loans-www/shopping/src/services/http/utils'

const finishFGTSProposal = async (
  contactType = 'whatsapp',
  simulationOptionUuid = ''
) => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'simulation_summary',
    method: 'put',
    body: {
      contact_type: contactType,
      simulation_option_uuid: simulationOptionUuid
    }
  })

  return data
}

export default { finishFGTSProposal }
