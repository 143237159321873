import { useState } from 'react'

import { isClient, storage } from 'utils'

import services from './services'

const useFgtsSuccess = () => {
  const [isClicked, setIsClicked] = useState(true)
  const [isButtonLoading, setButtonLoading] = useState(false)

  const simulationOptionUuid =
    (isClient() && storage.session.getItem('simulationOptionUuid')) ?? ''

  const handleAuthorizeContact = () => {
    setButtonLoading(true)

    services
      .finishFGTSProposal('whatsapp', simulationOptionUuid)
      .then(() => setIsClicked(true))
      .catch(() => setButtonLoading(false))
  }

  return { isButtonLoading, isClicked, handleAuthorizeContact }
}

export { useFgtsSuccess }
