import AvisoIcon from '@mobi/ds/static/icons/outlined/aviso.svg'

import './warning-box.scss'

const WarningBox = ({ children }) => {
  return (
    <div className="warning-box">
      <AvisoIcon className="warning-box__icon" />{' '}
      <span className="warning-box__text">{children}</span>
    </div>
  )
}

export default WarningBox
