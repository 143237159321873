import logoEscale from '@itau-loans-www/shopping/src/assets/images/escale.png'
import logoPaschoalotto from '@itau-loans-www/shopping/src/assets/images/paschoalotto.png'

const useSmsPageFgts = () => {
  const steps = [
    { title: 'Gerar proposta', status: 'done' },
    { title: 'Receber contato', status: 'ongoing' },
    { title: 'Finalizar contratação', status: 'open' },
    { title: 'Receber o dinheiro', status: 'open' }
  ]

  const partnersContent = {
    title: 'Parceiros autorizados Itaú Saque-Aniversário FGTS:',
    logos: [
      {
        img: logoEscale,
        alt: 'Logo Escale'
      },
      {
        img: logoPaschoalotto,
        alt: 'Logo Paschoalotto'
      }
    ]
  }

  return {
    steps,
    partnersContent
  }
}

export { useSmsPageFgts }
