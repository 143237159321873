import {
  Partners,
  TimeLine,
  SeeOffersSection
} from '@itau-loans-www/shopping/src/components'

import Img from 'components/Img'

import WarningBox from '../../../components/WarningBox'
import { useSmsPageFgts } from './hooks'
import './sms-page-fgts.scss'

const SmsPageFgts = ({ logoSrc }) => {
  const { partnersContent, steps } = useSmsPageFgts()

  return (
    <>
      <div className="sms-FGTS">
        <Img file={logoSrc} className="final_screen__logo" alt="Logo Itaú" />

        <h1 className="sms-FGTS__title">
          Em breve entraremos em contato com você
        </h1>

        <p className="sms-FGTS__alert">
          Atenção: não cobramos taxas antecipadas
        </p>
      </div>

      <WarningBox>
        Em caso de indisponibilidade na consulta do saldo por parte da Caixa
        Econômica, o tempo de atendimento pode ficar mais lento que o normal.
      </WarningBox>

      <Partners content={partnersContent} />

      <SeeOffersSection />

      <div className="final_screen__timeline-wrapper">
        <TimeLine title="Confira os próximos passos" steps={steps} />
      </div>
    </>
  )
}

export default SmsPageFgts
