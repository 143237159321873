import {
  DsIcon as Icon,
  If,
  Button,
  Loader,
  SafeTag
} from '@itau-loans-www/shopping/src/components'
import Layout from '@itau-loans-www/shopping/src/layouts/Summary'

import '../final-screen.scss'
import SmsPageFgts from './components/SmsPageFgts'
import { useFgtsSuccess } from './hooks'

import { useResult } from '../hooks'

const FgtsSuccess = () => {
  const { phone, isLoading } = useResult({ isToGetNumber: true })
  const { isButtonLoading, isClicked, handleAuthorizeContact } =
    useFgtsSuccess()

  return (
    <Layout>
      <Loader visible={isLoading} hasOverlay />
      <div className="final_screen">
        <SafeTag />
        <If
          condition={isClicked}
          renderIf={<SmsPageFgts phone={phone} />}
          renderElse={
            <div className="final_screen__content">
              <h1 className="final_screen__title">
                {`Para finalizar sua contratação clique no botão abaixo ;)`}
              </h1>

              <div className="final_screen__buttons-wrapper">
                <Button
                  fluid
                  id="phone-contact-btn"
                  isLoading={isButtonLoading}
                  disabled={isButtonLoading}
                  onClick={handleAuthorizeContact}
                >
                  <span className="final_screen__icon-wrapper">
                    <Icon
                      width="20px"
                      height="16px"
                      type="outlined"
                      name="autoriza-pelo-celular"
                    />
                  </span>
                  Autorizar contato do parceiro
                </Button>
              </div>

              <p className="final_screen__text">
                Ao clicar no botão, você autoriza um dos nossos parceiros a
                entrar em contato pelo telefone {phone}, e seguir com a proposta
              </p>
            </div>
          }
        />
      </div>
    </Layout>
  )
}

export default FgtsSuccess
